const evalFormattedResponses = (responses, ppeId) => {
  const newResponses = [];
  responses.map((section, secIndex) => {
    return section.map((question, quesIndex) => {
      if (question.employeeResponse || question.reviewerResponse) {
        const newQuestionObject = {
          id: ppeId,
          questionId: quesIndex,
          sectionId: secIndex,
          /* If PPE questions in a template are removed or updated after creation, questionText may be undefined. 
          Set questionText to an empty string to allow archiving. */
          questionText: question.questionText || '',
          employeeResponse: question.employeeResponse || '',
          reviewerResponse: question.reviewerResponse || ''
        };
        newResponses.push(newQuestionObject);
        return newQuestionObject;
      }
      return null;
    });
  });
  return newResponses;
};

const formatEvalComment = (comment, sections) => {
  const sectionTitle = getCommentSectionTitle(sections, comment);
  const newComment = {
    id: comment.id,
    sectionId: comment.sectionId,
    sectionTitle: sectionTitle ? sectionTitle : '',
    comment: comment.comment,
    createdBy: comment.createdBy,
    createdByEmail: comment.createdByEmail,
    employeeID: String(comment.createdByEmployeeId),
    createdOn: comment.createdOn
  };
  return newComment;
};

const getCommentSectionTitle = (sections, comment) => {
  const sectionForThisComment = sections.find(
    section => section.sectionId === comment.sectionId
  );

  if (!sectionForThisComment || !sectionForThisComment.sectionTitle) {
    return '';
  }

  const { sectionTitle } = sectionForThisComment;
  return sectionTitle;
};

const filterOutDeletedComments = (allComments, commentsToDelete) => {
  return allComments.filter(comment => {
    return !commentsToDelete.includes(comment.id);
  });
};

export { evalFormattedResponses, filterOutDeletedComments, formatEvalComment };
