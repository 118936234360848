import { useEffect, useState } from 'react';

const ONE_MIN_IN_MS = 60000;
const INTERVAL = 1 * ONE_MIN_IN_MS;

const useLatestVersion = () => {
  const [state, setState] = useState({
    data: null,
    error: false,
    loading: true
  });

  useEffect(() => {
    getLatestVersion();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(getLatestVersion, INTERVAL);
    return () => clearInterval(intervalId);
  });

  function getLatestVersion() {
    setState(state => ({ data: state.data, error: false, loading: true }));

    fetch('/meta.json', {
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        Expires: '0'
      }
    })
      .then(response => response.json())
      .then(meta => {
        setState({
          data: { version: meta.version },
          error: false,
          loading: false
        });
      })
      .catch(function(error) {
        console.error('Error fetching meta.json: ', error);
        setState({ data: null, error: true, loading: false });
      });
  }

  return state;
};

export default useLatestVersion;
