import React from 'react';
import StyledButtonPrimary from './ButtonPrimary';

const ReloadWindowButton = ({ callback }) => {
  const reload = () => {
    window.location.reload();
  };
  const action = callback || reload;
  return (
    <StyledButtonPrimary color="primary" label={'Reload'} onClick={action} />
  );
};

export default ReloadWindowButton;
