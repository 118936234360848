import React from 'react';
import PropTypes from 'prop-types';

import { DatePickerCalendar } from '../common';
import useStyles from '../../styles/goals/goalFormStyles';
import { Typography } from '@material-ui/core';

// display datepickers for important goal dates
const GoalDatesSection = ({
  dateInput,
  handleDateSelection,
  disabled,
  showCompletedDateError
}) => {
  const classes = useStyles();

  return (
    <div className={`${classes.sections} ${classes.dateContainer}`}>
      <div className={`${classes.datePicker} ${classes.date}`}>
        <DatePickerCalendar
          labelName="Start Date"
          id="startDate"
          disabled={disabled}
          selectedValue={dateInput.Start}
          onChange={handleDateSelection('Start')}
          setDisableFuture={false}
          isClearable
          inputVariant={'outlined'}
        />
      </div>
      <div className={`${classes.datePicker} ${classes.date}`}>
        <DatePickerCalendar
          labelName="Due Date"
          id="dueDate"
          disabled={disabled}
          selectedValue={dateInput.Due}
          onChange={handleDateSelection('Due')}
          setDisableFuture={false}
          isClearable
          inputVariant={'outlined'}
        />
      </div>
      <div className={`${classes.datePicker}`}>
        <DatePickerCalendar
          labelName="Completed Date"
          id="completedDate"
          disabled={disabled}
          selectedValue={dateInput.Completed}
          onChange={handleDateSelection('Completed')}
          setDisableFuture
          isClearable
          inputVariant={'outlined'}
        />
        {showCompletedDateError && (
          <Typography color="error" className={classes.errorText}>
            Completed date is required when status is Completed
          </Typography>
        )}
      </div>
    </div>
  );
};

GoalDatesSection.propTypes = {
  dateInput: PropTypes.objectOf(PropTypes.any).isRequired,
  handleDateSelection: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  showCompletedDateError: PropTypes.bool
};

GoalDatesSection.defaultProps = {
  showCompletedDateError: false
};

export default GoalDatesSection;
